export const environment = {
    production: true,

    Build: "3.1.55",       //  Replaced with actual build number when docker is build - see Dockerfile

    //  When these are configured as null values, the URL will be calculated based on the SPA's origin URL as:
    //  api:        [spa origin]/api
    //  geoserver:  [spa origin]/geoserver
    //  Those will then be routed to the proper server/container to handle the request
    //  (either via nginx reverse proxy or by Kubernete service routing rules).
    //  This avoids CORS issues and isolates https certs to a central place.
    apiBaseUrl: null,
    geoServerBaseUrl: null,

    //  Note: The ClientID is now calculated as the SPA Base URL with ":" and "/" characters stripped.  That is needed for us to be able
    //  to support multiple SPA clients in Identity Server.  See IdentityServer.Config for more info.
    //  This is calculated in SettingsService.IdentityServerClientID.
    //  The Identity Server URL is configured in assets/appsettings.json so that it can be dynamically changed at
    //  run-time when hosted.
    identityServerScopes: "openid profile TixApi email",//offline_access WebSPA

    //This is here because I don't have a better place to put it right now, and it is needed to know how to try and get the auth token.  i.e. if it's embedded then we need to check the SSO for the
    //  parent app token.  But if that token isn't linked to a person then we need to show the login page...
    isEmbedded: false,

    //This will store the config the app is embedded into to try an login using it
    embeddedAuthConfig: null,

    AllowedEmbedCoursettraUrls: ["https://dev.coursettra.com", "https://app.coursettra.com", "https://dsny.coursettra.com", "https://learning.digsafelynewyork.com", "https://learning.udigny.org"],

    EnableServiceWorker: true,
    AppUpdateRefreshIntervalSeconds: 0,          // Default is disabled - updates are triggered when the spa version changes so this should not be necessary
};
